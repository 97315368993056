import { Component, OnInit } from '@angular/core';
import {
  AlertService,
  BookieService,
  PadStartPipe,
  ToastService,
  copyClipboard,
  getCurrentSession,
} from '@common/models';
import { IonList, IonLabel, IonInput, IonNote, IonChip, IonButton, IonText, IonButtons, IonIcon, NavController, IonModal, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter } from "@ionic/angular/standalone";
import { IonAvatar, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg, IonItem } from '@ionic/angular/standalone';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { AbrNumberPipe, CardTotalComponent } from '@common/pipes';
import { TotalCard } from '@common/models';
import { AsyncPipe, CurrencyPipe, DatePipe, NgClass } from '@angular/common';
import { BaseClass } from '@common/globals';
import { MatChipsModule } from '@angular/material/chips';
import { RouterLink } from '@angular/router';
import { createOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { raffle, refreshRaffle, segments } from '../raffle-details-wrapper/raffle-details-wrapper.component'
import { FormsModule } from '@angular/forms';

addIcons({ createOutline });

const IonicComponents = [
  IonCardTitle, IonCardHeader, IonCardContent, IonCardSubtitle, IonCard, IonAvatar, IonImg, IonItem, IonList, IonInput, IonLabel, IonNote, IonChip,
  IonFooter, IonContent, IonTitle, IonToolbar, IonHeader, IonIcon, IonButtons, IonText, IonButton, IonModal,
];
const Pipes = [AbrNumberPipe, CurrencyPipe, DatePipe];

@Component({
  selector: 'section-details-raffle',
  templateUrl: './section-details-raffle.component.html',
  styleUrls: ['../raffle-details-wrapper/raffle-details-wrapper.component.scss'],
  standalone: true,
  imports: [IonicComponents, Pipes, MatFormFieldModule, MatInputModule, NgxMaskDirective, CardTotalComponent, AsyncPipe, MatChipsModule, PadStartPipe, RouterLink, NgxMaskDirective, FormsModule, NgClass, NgxMaskPipe],
})
export class SectionDetailsRaffleComponent extends BaseClass() implements OnInit {

  cards: Array<TotalCard> = [];

  allowEdit: boolean = false;

  session = getCurrentSession<'bookie'>();

  raffleLink: string;

  segments = segments;
  raffle = raffle;

  winnerNumber: number;

  constructor(
    private toastService: ToastService,
    private navController: NavController,
    private bookieService: BookieService,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  async initialize() {
    if (!this.raffle) return;

    if (this.raffle.status === 'PENDING') this.allowEdit = true;

    if (this.raffle.status === 'ACTIVE')
      this.raffleLink = `https://portaldasorte.site/home/promotion/${btoa(this.raffle.id)}`;

    this.cards = [
      { label: 'Números', total: this.raffle.amount_numbers, type: 'abr-number' },
      { label: 'Começa em', total: this.raffle.start_at, type: 'number' },
      { label: 'Disponíveis', total: this.raffle.qtd_array, type: 'fmt-number' },
      { label: 'Valor da Cota', total: this.raffle.quota_value, type: 'currency' },
      { label: 'Já Vendido', total: this.raffle.total_sellings, type: 'abr-number' },
      { label: 'Status', total: this.raffle.status, type: 'raffle-status' },
      { label: 'Já Arrecadado', total: this.raffle.total_sellings * this.raffle.quota_value, type: 'currency' }
    ];
  }

  async copyRaffleLink() {
    if (this.raffle?.status !== 'ACTIVE') return;

    const copied = await copyClipboard(this.raffleLink);
    if (copied) return this.toastService.present({ message: 'Link copiado', color: 'success' });
    this.toastService.present({ message: 'Erro ao copiar link', color: 'danger' });
  }

  finishRaffle(ModalRef: IonModal) {
    const drawnNumber: number = this.winnerNumber;

    if (!drawnNumber) return this.toastService.present({ message: 'Você precisa informar o número sorteado', color: 'danger' });
    if (drawnNumber > (this.raffle.amount_numbers + this.raffle.start_at)) return this.toastService.present({ message: 'O número sorteado não pode ser maior que a quantidade de números da rifa', color: 'danger' });
    if (drawnNumber < this.raffle.start_at) return this.toastService.present({ message: 'O número sorteado não pode ser menor que o número inicial da rifa', color: 'danger' });

    this.bookieService.finishRaffle(this.raffle.id, drawnNumber).then((res) => {
      console.log(res);
      this.toastService.present({ message: 'Rifa finalizada com sucesso' });
      refreshRaffle.emit();
      ModalRef.dismiss();
    }).catch((err) => {
      console.log(err);
      let message = 'Não foi possível finalizar a rifa, tente novamente';
      if (err?.error?.response === 409) message = 'O número informado não foi vendido';
      this.toastService.present({ message, color: 'danger' });
    });
  }

  async closeRaffle(modalRef: IonModal) {
    if (this.raffle?.total_sellings < this.raffle?.amount_numbers) {
      const confirm = await this.alertService.confirm('Atenção', 'A rifa ainda não foi vendida completamente, deseja realmente encerrar?');
      if (!confirm) return;
    }

    modalRef.present();
  }

  goBack() {
    this.navController.back();
  }

}
