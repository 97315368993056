import { Component } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonList, IonIcon, IonMenuToggle, IonLabel, IonItem, IonContent } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { home, homeOutline, ticket, ticketOutline, people, peopleOutline, settings, settingsOutline, logOut, logOutOutline, add, addOutline } from 'ionicons/icons';
import { BaseClass } from '@common/globals';
import { AuthService } from '@common/services';

addIcons({ home, homeOutline, ticket, ticketOutline, people, peopleOutline, settings, settingsOutline, logOut, logOutOutline, add, addOutline })

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  standalone: true,
  imports: [IonContent, IonItem, RouterLink, RouterLinkActive, IonList, IonIcon, IonMenuToggle, IonLabel],
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent extends BaseClass() {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    super();
  }

  pages: Array<AppPage> = [
    { title: 'Dashboard', icon: 'home', path: ['/home', 'dashboard'] },
    { title: 'Minhas Rifas', icon: 'ticket', path: ['/home', 'raffles'] },
    { title: 'Meu Perfil', icon: 'people', path: ['/home', 'profile'] },
  ];

  logout() {
    this.authService.logout();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

}

export type AppPage = {
  title: string;
  icon: string;
  path: Array<string>;
}
