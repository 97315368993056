import { Component, OnInit, inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IonList, IonButton, IonButtons, IonIcon, IonText, IonContent, IonAvatar, NavController } from "@ionic/angular/standalone";
import {
  AlertService,
  BaseClass,
  MAX_IMAGES_RAFFLE,
  MAX_IMAGE_SIZE,
  RAFFLES_CONFIG,
  Raffle,
  RaffleStatus,
  Session,
  TRaffleForm,
  getFormRaffle,
  inputOnCurrencyInput,
  session$,
} from '@common/models';
import { RaffleService, ToastService, LoadingService, FirebaseStorageService } from '@common/services'
import { DEFAULT_RAFFLE_SIZE } from '@common/globals';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { addIcons } from 'ionicons';
import { saveOutline, closeOutline, add } from 'ionicons/icons';
import { OperationInProgressComponent, BreadcrumbsComponent, Breadcrumb } from '@common/components';
import { ActivatedRoute, Router } from '@angular/router';
import { LiveAnnouncer } from '@angular/cdk/a11y';

const IONIC_COMPONENTS = [IonList, IonButton, IonButtons, IonIcon, IonContent, IonText];
const NG_COMPONENTS = [ReactiveFormsModule, MatDatepickerModule];
const MATERIAL_COMPONENTS = [MatFormFieldModule, MatInputModule, MatIconModule];

addIcons({ saveOutline, closeOutline, add });

@Component({
  selector: 'app-new-raffle',
  templateUrl: './new-raffle.component.html',
  styleUrls: ['./new-raffle.component.scss'],
  standalone: true,
  providers: [NgxMaskPipe],
  imports: [IonAvatar, IONIC_COMPONENTS, NG_COMPONENTS, MATERIAL_COMPONENTS, OperationInProgressComponent, BreadcrumbsComponent, NgxMaskDirective],
})
export class NewRaffleComponent extends BaseClass() implements OnInit {

  addOnBlur = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  announcer = inject(LiveAnnouncer);

  raffle: Raffle;
  form: FormGroup<TRaffleForm>;

  breadcrumbs: Array<Breadcrumb> = [
    { link: '/home/raffles', text: 'Rifas' },
    { link: null, text: 'Nova Rifa' },
  ];

  today: Date = new Date();

  imagesBase64: Array<string> = [];
  maxImagesAllowed = MAX_IMAGES_RAFFLE;

  inputOnCurrencyInput = inputOnCurrencyInput

  constructor(
    private router: Router,
    private toastService: ToastService,
    private raffleService: RaffleService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private firebaseStorageService: FirebaseStorageService,
    private navController: NavController,
    private route: ActivatedRoute,
    private maskPipe: NgxMaskPipe,
  ) {
    super();
  }

  ngOnInit() {
    this.initialize();
  }

  private initialize() {
    const raffleId = this.route?.snapshot?.paramMap?.get('id');
    if (!raffleId) {
      // this.form = getFormRaffle({
      //   amount_numbers: DEFAULT_RAFFLE_SIZE,
      //   award: '',
      //   description_award: '',
      //   draw_prediction: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 14),
      //   flash_numbers: [],
      //   name: '',
      //   quota_value: null,
      // });
      this.form = getFormRaffle({
        amount_numbers: DEFAULT_RAFFLE_SIZE,
        award: '',
        description_award: '',
        draw_prediction: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 14),
        name: '',
        quota_value: null,
      });
      return;
    }

    this.getRaffle(raffleId);
  }

  getRaffle(raffleId: string) {
    this.raffleService.getRaffleById(raffleId).then((res) => {
      this.raffle = res;
      this.form = getFormRaffle(this.raffle);
      this.form.controls.amount_numbers.disable()
      this.form.controls.start_at.disable();
      // this.form.controls.quota_value.disable();

      if ((['CANCELLED', 'CLOSED'] as Array<RaffleStatus>).includes(this.raffle?.status)) this.form.disable();
    }).catch((err) => {
      console.log(err);
      this.toastService.present({ message: 'Rifa não encontrada', color: 'danger' });
      this.goBack();
    })
  }

  private async getFormValue(): Promise<Partial<Raffle> | null> {
    const { description_award, award, name, amount_numbers, draw_prediction, start_at } = this.form.value;
    const _quota_aux = this.form.controls.quota_value.value;
    const session = session$.value as Session<'bookie'>;

    const presentErrorMessage = (message: string): null => {
      this.alertService.presentAlert("Aviso", message);
      return null;
    }

    const quota_value = _quota_aux ? _quota_aux.replace("R$", "") : null;

    if (+amount_numbers <= 0 || +amount_numbers > RAFFLES_CONFIG.MAX_NUMBERS_CREATE) return presentErrorMessage(`Quantidade de números deve ser maior que 0 e menor ou igual ${this.maskPipe.transform(RAFFLES_CONFIG.MAX_NUMBERS_CREATE, 'separator', { thousandSeparator: '.' })}`);
    if (+start_at <= 0 || +start_at > RAFFLES_CONFIG.MAX_NUMBERS_START) return presentErrorMessage(`'Começa em' deve ser maior que 0 e até ${this.maskPipe.transform(RAFFLES_CONFIG.MAX_NUMBERS_START, 'separator', { thousandSeparator: '.' })}`);
    if (!+quota_value || +quota_value < RAFFLES_CONFIG.MIN_QUOTA_VALUE) return presentErrorMessage(`Valor da cota deve ser maior ou igual à R$ ${RAFFLES_CONFIG.MIN_QUOTA_VALUE.toFixed(2)}`);
    if (new Date(draw_prediction).getTime() <= new Date().getTime()) return presentErrorMessage("Data do sorteio deve ser maior que a data atual");
    if (!award) return presentErrorMessage("Prêmio é obrigatório");
    if (!description_award) return presentErrorMessage("Descrição do prêmio é obrigatória");
    if (!name) return presentErrorMessage("Você deve informar o nome da rifa");

    if (this.raffle?.id) {
      return {
        name,
        award,
        description_award,
        draw_prediction: new Date(draw_prediction),
        quota_value: parseFloat((+`${quota_value}`.replace("R$", "").replace(",", ".")).toFixed(2)),
      };
    } else {
      return {
        ...this.raffle,
        description_award,
        award,
        name,
        amount_numbers: amount_numbers ? parseInt(`${amount_numbers}`) : null,
        draw_prediction: new Date(draw_prediction),
        quota_value: quota_value ? parseFloat((+`${quota_value}`.replace("R$", "").replace(",", ".")).toFixed(2)) : null,
        start_at: start_at ? parseInt(`${start_at}`) : null,
        bookie_id: session.bookie.id,
      }
    }

  }

  async save() {
    if (this.raffle?.id) return this.update();
    this.doSave();
  }

  private async doSave() {
    const raffle = await this.getFormValue();

    if (!raffle) return;

    const paths = this.imagesBase64?.map((image, index) => `raffles/${raffle.name}/${index + 1}-${Date.now()}.${image.split(';')[0].split('/')[1]}`);
    const promisesImages = paths?.map((path, index) => this.firebaseStorageService.uploadFromBase64(path, this.imagesBase64[index]));
    let images: Array<string> = [];

    this.toggleForm(false);
    const loading = await this.loadingService.presentLoading("Salvando rifa... Aguarde");

    try {
      if (promisesImages?.length) {
        const uploads = await Promise.all(promisesImages);
        const uploadPaths = uploads.map((upload) => upload?.ref?.fullPath);
        images = await Promise.all(uploadPaths.map((path) => this.firebaseStorageService.getDownloadURL(path)));
      }
    } catch (err) {
      console.error('save', err);
      this.toastService.present({ message: "Erro ao salvar imagens", color: "danger" });
    } finally {
      this.raffleService.insert({
        ...raffle,
        image: images,
      }).then((res) => {
        this.toastService.present({ message: "Rifa criada com sucesso", color: "success" });
        this.router.navigate(['/home', 'raffles', res?.id])
      }).catch((err) => {
        console.log('err', err);
        // this.firebaseStorageService.deleteFile(fullPath);
        paths?.forEach((path) => this.firebaseStorageService.deleteFile(path));
        this.toggleForm(true);
      }).finally(() => {
        loading.dismiss();
      });
    }
  }

  private async update() {
    const raffle = await this.getFormValue();

    if (!raffle) return;

    this.toggleForm(false);
    const loading = await this.loadingService.presentLoading("Atualizando rifa... Aguarde");

    this.raffleService.update(this.raffle.id, raffle).then((res) => {
      this.toastService.present({ message: "Rifa atualizada com sucesso", color: "success" });
      this.goBack();
    }).catch((err) => {
      console.log(err);
      this.toastService.present({ message: "Erro ao atualizar rifa", color: "danger" });
      this.toggleForm(true);
    }).finally(() => {
      loading.dismiss();
    });
  }

  async forbiddenField(key: keyof Pick<TRaffleForm, 'amount_numbers' | 'start_at' | 'quota_value'>) {
    let fieldLabel = '';

    switch (key) {
      case 'amount_numbers': fieldLabel = 'Quantidade de números'; break;
      case 'start_at': fieldLabel = 'Começa em'; break;
      // case 'quota_value': fieldLabel = 'Valor da cota'; break;
    }

    await this.alertService.presentAlert('Aviso', `O campo '${fieldLabel}' não pode ser alterado após a criação da rifa`);
  }

  addImage() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files[0];

      if (file.size > MAX_IMAGE_SIZE) {
        const bitsInMB = 1048576;
        this.alertService.presentAlert('Aviso', `Imagem deve ter no máximo ${MAX_IMAGE_SIZE / bitsInMB}MB\n\nImage selecionada: ${(file.size / bitsInMB).toFixed(2)}MB`);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const base64 = e.target.result as string;
        if (this.raffle?.id)
          this.uploadNewImage(base64)
        else
          this.imagesBase64 = [...this.imagesBase64, base64];
      }
      reader.readAsDataURL(file);
    }
    input.click();
  }

  private async uploadNewImage(base64: string) {
    const loading = await this.loadingService.presentLoading("Salvando imagem... aguarde");

    this.toggleForm(false);

    const path = `raffles/${this.raffle.name}/${(this.raffle.image?.length) + 1}-${Date.now()}.${base64.split(';')[0].split('/')[1]}`;
    this.firebaseStorageService.uploadFromBase64(path, base64).then(async (uploadRes) => {
      const { id, image } = this.raffle;
      const downloadUrl = await this.firebaseStorageService.getDownloadURL(uploadRes?.ref?.fullPath);
      const newImagesArr = [...image, downloadUrl];
      this.raffleService.update(id, { image: newImagesArr }).then((res) => {
        this.raffle.image = newImagesArr;
      }).catch((err) => {
        console.log(err);
        this.firebaseStorageService.deleteFile(uploadRes?.ref?.fullPath);
        this.toastService.present({ message: "Ocorreu um erro ao fazer o upload da imagem, tente novamente", color: "danger" });
      }).finally(() => {
        loading.dismiss();
        this.toggleForm(true);
      });
    }).catch((err) => {
      console.log(err);
      this.toastService.present({ message: "Ocorreu um erro ao fazer o upload da imagem, tente novamente", color: "danger" });
      loading.dismiss();
      this.toggleForm(true);
    });
  }

  removeImage(index: number, image: string = null) {
    if (image && this.raffle.id) return this.removeUploadedImage(image);
    this.imagesBase64.splice(index, 1);
  }

  private async removeUploadedImage(downloadUrl: string) {
    const confirm = await this.alertService.confirm("Tem certeza?", "Deseja mesmo remover essa imagem? Essa operação não poderá ser desfeita");
    if (!confirm) return;

    const loading = await this.loadingService.presentLoading("Excluindo imagem...")
    this.toggleForm(false);

    this.firebaseStorageService.deleteFile(downloadUrl).then(() => {
      const indexArrImage = this.raffle.image?.findIndex(x => x === downloadUrl);
      this.raffle.image.splice(indexArrImage, 1);
      this.raffleService.update(this.raffle.id, { image: this.raffle.image }).then((res) => {
        this.toastService.present({ message: "Imagem excluída com sucesso!", color: "success" });
      }).catch((err) => {
        console.log(err);
        this.toastService.present({ message: "Ocorreu um erro ao excluir essa imagem, tente novamente", color: "danger" });
      });
    }).catch((err) => {
      console.log(err);
      this.toastService.present({ message: "Ocorreu um erro ao excluir essa imagem, tente novamente", color: "danger" });
    }).finally(() => {
      loading.dismiss();
      this.toggleForm(true);
    });
  }

  toggleForm(shoulEnable: boolean) {
    if (shoulEnable) {
      this.form.enable();
      if (this.raffle.id) {
        this.form.controls.amount_numbers.disable();
        this.form.controls.start_at.disable();
        this.form.controls.quota_value.disable();
      }
    }
    else this.form.disable();

  }

  goBack() {
    this.navController.back();
    // this.router.navigate(['..', '..'], { relativeTo: this.route });
  }
}
