import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { RafflesComponent } from './home/raffles/raffles.component';
import { APP_NAME, ChangePasswordComponent, matchUrlChangePassword } from '@common/globals';
import { NewRaffleComponent } from './home/raffles/new-raffle/new-raffle.component';
import { RaffleWrapperComponent } from './home/raffles/raffle-wrapper/raffle-wrapper.component';
import { authGuard } from '@common/guards';
import { LoginComponentCommon, NotFoundComponent, ForgotPasswordComponent } from '@common/components'
import { MpConnectedSuccessfullyComponent } from './mp-connected-successfully/mp-connected-successfully.component';
import { ProfileComponent } from './home/profile/profile.component';
import { RaffleDetailsWrapperComponent } from './home/raffles/raffle-details/raffle-details-wrapper/raffle-details-wrapper.component';
import { SectionBuyersRafflesComponent } from './home/raffles/raffle-details/section-buyers-raffles/section-buyers-raffles.component';
import { SectionDetailsRaffleComponent } from './home/raffles/raffle-details/section-details-raffle/section-details-raffle.component';
import { TransactionDetailsComponent } from './home/raffles/raffle-details/section-buyers-raffles/transaction-details/transaction-details.component';
import { SectionFlashNumbersComponent } from './home/raffles/raffle-details/section-flash-numbers/section-flash-numbers.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponentCommon, title: APP_NAME + ' - Login' },
  { path: 'mp-connected-successfully', component: MpConnectedSuccessfullyComponent, title: APP_NAME + ' - Mercado Pago Conectado' },
  { path: 'forgot-password', component: ForgotPasswordComponent, title: APP_NAME + ' - Esqueci a Senha' },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard],
    title: APP_NAME + ' - Home',
    children: [
      { path: 'dashboard', component: DashboardComponent, title: APP_NAME + ' - Dashboard' },
      {
        path: 'raffles',
        component: RaffleWrapperComponent,
        title: APP_NAME + ' - Rifas',
        children: [
          { path: 'list', component: RafflesComponent, title: APP_NAME + ' - Rifas' },
          { path: 'new', component: NewRaffleComponent, title: APP_NAME + ' - Rifa | Nova Rifa' },
          { path: 'new/:id', component: NewRaffleComponent, title: APP_NAME + ' - Rifa | Editar Rifa' },
          {
            path: ':id',
            component: RaffleDetailsWrapperComponent,
            title: APP_NAME + ' - Rifa | Detalhes',
            children: [
              { path: 'transactions', component: SectionBuyersRafflesComponent, title: APP_NAME + ' - Rifa | Editar Rifa' },
              { path: 'transactions/:id', component: TransactionDetailsComponent, title: APP_NAME + ' - Rifa | Editar Rifa' },
              { path: 'details', component: SectionDetailsRaffleComponent, title: APP_NAME + ' - Rifa | Detalhes' },
              { path: 'flash-numbers', component: SectionFlashNumbersComponent, title: APP_NAME + ' - Rifa | Números Relâmpago' },
              { path: '', redirectTo: 'details', pathMatch: 'full' },
            ]
          },
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: '**', component: NotFoundComponent, title: APP_NAME + ' - Not Found' },
        ],
      },
      { path: 'profile', component: ProfileComponent, canActivate: [authGuard], title: APP_NAME + ' - Perfil' },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', component: NotFoundComponent, title: APP_NAME + ' - Not Found' },
    ]
  },
  { path: '', redirectTo: 'home', pathMatch: 'full', },
  { matcher: matchUrlChangePassword, component: ChangePasswordComponent, title: APP_NAME + ' - Alterar Senha' }, // keep at the end to prevent unecessary checks
  { path: '**', component: NotFoundComponent, title: APP_NAME + ' - Not Found' },
];
