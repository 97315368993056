<ion-list lines="none" mode="md" class="details-raffle-form margin-md padding-top-sm">
  <div id="cards">
    @for (card of cards; track $index) {
      <card-total [totalCard]="card" [center]="true" />
    }
  </div>

  @switch (raffle?.status) {
    @case ("PENDING") {
      <ion-chip id="note" [outline]="false" class="italic padding-vertical-md" color="danger"> Aguardando Aprovação </ion-chip>
    }

    @case ("SOLDOUT") {
      <ion-chip id="note" [outline]="false" class="italic padding-vertical-md" color="warning"> Rifa Esgotada. </ion-chip>
    }
  }

  <mat-form-field id="name" appearance="outline">
    <label>Nome</label>
    <input matInput placeholder="Nome" [value]="raffle.name" readonly />
  </mat-form-field>

  <mat-form-field id="award" appearance="outline">
    <label>Prêmio</label>
    <input matInput placeholder="Prêmio" [value]="raffle.award" readonly />
  </mat-form-field>

  <mat-form-field id="bookie" appearance="outline">
    <label>Rifeiro</label>
    <input matInput placeholder="Rifeiro" [value]="raffle.bookie.name + ((session | async)?.bookie?.id === raffle.bookie?.id ? ' (Você)' : '')" readonly />
  </mat-form-field>

  <mat-form-field id="description" appearance="outline">
    <label>Descrição</label>
    <textarea [rows]="2" matInput placeholder="Descrição" [value]="raffle.description_award" readonly></textarea>
  </mat-form-field>

  <mat-form-field id="create_at" appearance="outline">
    <label>Criado em</label>
    <input matInput placeholder="Criado em" [value]="raffle.create_at | date: 'dd/MM/yyyy'" readonly />
  </mat-form-field>

  <mat-form-field id="draw_prediction" appearance="outline">
    <label>Previsão Sorteio</label>
    <input matInput placeholder="Previsão Sorteio" [value]="raffle.draw_prediction | date: 'dd/MM/yyyy'" readonly />
  </mat-form-field>

  <mat-form-field id="draw_date" appearance="outline">
    <label>Sorteio</label>
    <input matInput placeholder="Sorteio" [value]="raffle.draw_date ? (raffle.draw_date | date: 'dd/MM/yyyy') : 'Não sorteada'" readonly />
  </mat-form-field>

  @if (raffle?.status === "CLOSED") {
    <ion-card id="winner" class="prevent-animation" mode="md">
      <ion-card-content>
        <h2 class="bold">Vencedor</h2>
        <div class="flex">
          @if (raffle.user) {
            <ion-label class="flex-column">
              <div class="fit-content margin-vertical-md">
                <li class="winner padding-inline-md padding-vertical-sm border-radius-md bold">{{ raffle.drawn_number | padStart: raffle?.amount_numbers + (raffle?.start_at || 0) || 0 }}</li>
              </div>
              <ion-text class="bold">{{ raffle.user.name }}</ion-text>
              <ion-text class="italic">{{ raffle.user.cpf | mask: "CPF_CNPJ" }}</ion-text>
              <ion-text class="italic">{{ raffle.user.email }}</ion-text>
              <ion-text class="italic">{{ raffle.user.phone | mask: "(00) 0000-0000||(00) 00000-0000" }}</ion-text>
            </ion-label>
          } @else {
            <ion-label>
              <ion-text class="italic"> Essa rifa não teve um vencedor </ion-text>
            </ion-label>
          }
        </div>
      </ion-card-content>
    </ion-card>
  }

  @if (raffle?.status === "ACTIVE") {
    <mat-form-field id="link" appearance="outline">
      <label> Link </label>
      <input matInput [class.italic]="!raffleLink" placeholder="Link da Rifa" [value]="raffleLink || 'Disponível após aprovação'" readonly />

      @if (raffleLink) {
        <ion-button fill="solid" slot="end" (click)="copyRaffleLink()">
          <ion-text> Copiar </ion-text>
        </ion-button>
      }
    </mat-form-field>
  }
</ion-list>

<div id="buttons" [ngClass]="{ 'margin-inline-md padding-bottom-md': isMobile }">
  <ion-buttons class="grid-two-columns">
    <ion-button fill="clear" [routerLink]="['..']">
      <ion-text> VOLTAR </ion-text>
    </ion-button>

    @if (raffle?.id && raffle?.status != "CLOSED") {
      <div class="flex align-center justify-center gap-sm">
        <ion-button fill="solid" color="danger" (click)="closeRaffle(ModalRef)">
          <ion-text> Encerrar Rifa </ion-text>
          <ion-modal id="finish-raffle-modal" class="blurred-bg" #ModalRef [keyboardClose]="false" [backdropDismiss]="false">
            <ng-template>
              <div id="container">
                <ion-header>
                  <ion-toolbar>
                    <ion-title> Encerrar Rifa </ion-title>
                  </ion-toolbar>
                </ion-header>

                <ion-content>
                  <ion-list lines="none" class="no-padding-top">
                    <mat-form-field appearance="outline">
                      <input matInput placeholder="Número Vencedor" type="text" mask="separator" [dropSpecialCharacters]="true" thousandSeparator="." [(ngModel)]="winnerNumber" maxlength="14" />
                    </mat-form-field>
                  </ion-list>
                </ion-content>

                <ion-footer>
                  <ion-toolbar>
                    <ion-buttons slot="start">
                      <ion-button fill="clear" (click)="ModalRef.dismiss()">
                        <ion-text> Cancelar </ion-text>
                      </ion-button>
                    </ion-buttons>

                    <ion-buttons slot="end">
                      <ion-button fill="solid" color="danger" (click)="finishRaffle(ModalRef)">
                        <ion-text> Encerrar </ion-text>
                      </ion-button>
                    </ion-buttons>
                  </ion-toolbar>
                </ion-footer>
              </div>
            </ng-template>
          </ion-modal>
        </ion-button>

        <ion-button fill="solid" [routerLink]="['/home', 'raffles', 'new', raffle.id]">
          <ion-text> EDITAR </ion-text>
          <ion-icon name="create-outline" />
        </ion-button>
      </div>
    }
  </ion-buttons>
</div>
