<ion-content>
  <section id="cards" class="padding-sm">
    @for (cardTotal of cards; track $index) {
      <card-total [totalCard]="cardTotal" />
    }
  </section>

  <ion-list-header>
    <ion-label class="padding-inline-sm no-margin-top"> Minhas Últimas Rifas </ion-label>
  </ion-list-header>

  <section id="active-raffles-preview" class="margin-bottom-md">
    <raffles-list [showAllButton]="true" [raffles]="raffles" app="bookie" />
  </section>
</ion-content>
