<ion-content>
  <div class="margin-md">
    <raffles-list [raffles]="raffles" app="bookie" />
  </div>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button [routerLink]="['/home', 'raffles', 'new']">
      <ion-icon name="add" />
    </ion-fab-button>
  </ion-fab>
</ion-content>
