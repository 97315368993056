<ion-content>
  <breadcrumbs [breadcrumbs]="breadcrumbs" />

  <div id="content">
    @if (form) {
      <ion-list id="form" lines="none" mode="md" [formGroup]="form" class="margin-md">
        <mat-form-field id="name" appearance="outline">
          <label>Nome</label>
          <input matInput placeholder="Nome" formControlName="name" />
        </mat-form-field>

        <mat-form-field id="award" appearance="outline">
          <label>Prêmio</label>
          <input matInput placeholder="Prêmio" formControlName="award" />
        </mat-form-field>

        <mat-form-field id="award-description" appearance="outline">
          <label>Descrição do Prêmio</label>
          <textarea matInput placeholder="Descrição do Prêmio" formControlName="description_award" [rows]="isMobile ? 6 : 3"></textarea>
        </mat-form-field>

        <mat-form-field id="draw-prediction" appearance="outline">
          <label>Previsão de Sorteio</label>
          <input matInput [matDatepicker]="drawPredictionPicker" placeholder="Previsão de Sorteio" formControlName="draw_prediction" [min]="today" readonly (click)="drawPredictionPicker.open()" />
          <mat-datepicker-toggle matSuffix [for]="drawPredictionPicker" />
          <mat-datepicker #drawPredictionPicker />
        </mat-form-field>

        <mat-form-field class="override-disabled-cursor" id="amount-numbers" appearance="outline" (click)="raffle?.id ? forbiddenField('amount_numbers') : null">
          <label>Quantidade de Números</label>
          <input matInput placeholder="Quantidade de Números" type="text" formControlName="amount_numbers" mask="separator" thousandSeparator="." maxlength="10" [dropSpecialCharacters]="true" />
        </mat-form-field>

        <mat-form-field class="override-disabled-cursor" id="start-at" appearance="outline" (click)="raffle?.id ? forbiddenField('start_at') : null">
          <label>Começa em</label>
          <input matInput placeholder="Começa em" type="text" formControlName="start_at" mask="separator" thousandSeparator="." [dropSpecialCharacters]="true" />
        </mat-form-field>

        <mat-form-field class="override-disabled-cursor" id="quota" appearance="outline">
          <label>Valor da Cota</label>
          <input #inputQuota matInput placeholder="Valor do Número" formControlName="quota_value" mask="0*.09" prefix="R$ " [dropSpecialCharacters]="false" (input)="inputOnCurrencyInput(inputQuota, $event)" />
        </mat-form-field>

        <div id="images">
          <h3 class="margin-bottom-lg">Imagens</h3>
          <div id="avatars">
            @for (image of raffle?.id ? raffle.image : imagesBase64; track $index) {
              <ion-avatar>
                <img [src]="image.toString()" />
                @if (!form?.disabled) {
                  <button [disabled]="form?.disabled" (click)="removeImage($index, image)">
                    <ion-icon name="close-outline" />
                  </button>
                }
              </ion-avatar>
            }
            @if ((raffle?.id ? raffle.image.length : imagesBase64.length) < maxImagesAllowed && !form?.disabled) {
              <ion-avatar id="add-image" (click)="form?.disabled ? null : addImage()">
                <ion-icon name="add" />
              </ion-avatar>
            }
          </div>
        </div>

        <div id="buttons">
          <ion-buttons class="grid-two-columns">
            <ion-button fill="clear" (click)="goBack()">
              <ion-text> CANCELAR </ion-text>
            </ion-button>

            @if (raffle?.status !== "CLOSED") {
              <ion-button fill="solid" (click)="save()" [disabled]="form.invalid">
                <ion-text> SALVAR </ion-text>
                <ion-icon slot="end" name="save-outline"></ion-icon>
              </ion-button>
            }
          </ion-buttons>
        </div>
      </ion-list>
    } @else {
      <operation-in-progress />
    }
  </div>
</ion-content>
