<div id="container">
  <ion-header>
    <ion-toolbar>
      <ion-title>Números Relâmpago</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-list lines="none" class="flex-column gap-sm padding-inline-md no-padding-top">
      @for (flashNumber of flashNumbers; track $index) {
        <mat-form-field appearance="outline">
          <input class="input ng-dirty ng-touched" matInput placeholder="Número" type="number" [min]="min" [max]="max" [(ngModel)]="flashNumber.flash_number" (keyup)="onInput($event)" />
          @if (flashNumber.flash_number > max) {
            <mat-error> O número não pode ser maior que {{ max }} </mat-error>
          } @else if (flashNumber.flash_number < min) {
            <mat-error> O número não pode ser menor que {{ min }} </mat-error>
          }

          @if (flashNumbers?.length > 1) {
            <ion-button color="dark" fill="outline" slot="end" class="margin-sm" (click)="removeFlashNumber($index)">
              <ion-icon slot="icon-only" name="trash-outline" />
            </ion-button>
          }
        </mat-form-field>
      }

      <ion-button fill="clear" (click)="addFlashNumberArr()">
        <ion-text> ADICIONAR NÚMERO </ion-text>
        <ion-icon slot="end" name="add" />
      </ion-button>
    </ion-list>
  </ion-content>

  <ion-footer>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button fill="clear" (click)="dismiss()">
          <ion-text> CANCELAR </ion-text>
          <ion-icon slot="end" name="close" />
        </ion-button>
      </ion-buttons>

      <ion-buttons slot="end">
        <ion-button fill="solid" (click)="save()">
          <ion-text> SALVAR </ion-text>
          <ion-icon slot="end" name="save" />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</div>
