import { Component, EventEmitter, OnInit } from '@angular/core';
import { BaseClass, BreadcrumbsComponent, FlashNumberRaffle, OperationInProgressComponent, RaffleService, ToastService, navigateReplace } from '@common/globals';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Breadcrumb } from '@common/components';
import { Raffle } from '@common/models';
import { createOutline, trashOutline, closeOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { IonContent, IonSegmentButton, IonSegment, IonLabel } from "@ionic/angular/standalone";
import { FormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

addIcons({ createOutline, trashOutline, closeOutline });

export let breadcrumbs: Array<Breadcrumb> = [
  { link: '..', text: 'Rifas' },
  { link: null, text: 'Detalhes Rifa' },
];

export let segments: Array<Segment> = [
  { path: 'details', label: 'Detalhes' },
  { path: 'flash-numbers', label: 'Números Relâmpago' },
];

export let activeSegment: SegmentValue = segments[0].path;

export let raffle: Raffle;

export let flashNumbers$: BehaviorSubject<Array<FlashNumberRaffle>> = new BehaviorSubject<Array<FlashNumberRaffle>>([]);

export let refreshRaffle: EventEmitter<void> = new EventEmitter<void>();

export let UpdateFlashNumbers: EventEmitter<void> = new EventEmitter<void>();

@Component({
  selector: 'app-raffle-details-wrapper',
  templateUrl: './raffle-details-wrapper.component.html',
  styleUrls: ['./raffle-details-wrapper.component.scss'],
  standalone: true,
  imports: [IonLabel, IonSegment, IonSegmentButton, IonContent, BreadcrumbsComponent, FormsModule, RouterModule, OperationInProgressComponent],
})
export class RaffleDetailsWrapperComponent extends BaseClass() implements OnInit {

  segments = segments;
  breadcrumbs = breadcrumbs;
  activeSegment = activeSegment;
  raffle = raffle;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private raffleService: RaffleService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    const id = this.route?.snapshot?.paramMap?.get('id');
    if (!id) this.navigateRaffles('Rifa não encontrada');

    const sub = UpdateFlashNumbers.pipe().subscribe({
      next: () => this.getFlashNumbers(id),
    })
    this.appendSubscription(sub);

    this.getRaffle(id);
    this.getFlashNumbers(id);

    const sub2 = refreshRaffle.pipe().subscribe({
      next: () => this.getRaffle(id),
    });
    this.appendSubscription(sub2);
  }

  getFlashNumbers(idRaffle: string) {
    this.raffleService.getFlashNumbers(idRaffle).then((res) => {
      flashNumbers$.next(res.message);
    }).catch((err) => {
      this.toastService.present({ message: 'Não foi possível carregar os números relâmpago, tente novamente', color: 'danger' });
      console.log(err);
    });
  }

  getRaffle(id: string) {
    this.raffleService.getRaffleById(id).then((res) => {
      setTimeout(() => {
        this.raffle = raffle = res;
      }, 200);
      this.raffle = raffle = null;
      if (this.breadcrumbs?.[1]) this.breadcrumbs[1].text = raffle?.name;
      if (raffle?.status !== 'PENDING' && !segments?.find((s) => s.path === 'transactions')) {
        segments.push({ path: 'transactions', label: 'Compradores' });
        this.segments = segments;
      }
    }).catch((err) => {
      console.log(err);
      this.navigateRaffles('Não foi possível carregar os detalhes da rifa');
    });
  }

  private navigateRaffles(message: string = null) {
    if (message) this.toastService.present({ message, color: 'danger' });
    navigateReplace(this.router, ['/', 'home', 'raffles']);
  }
}

type Segment = {
  path: SegmentValue;
  label: string;
};

type SegmentValue = 'details' | 'transactions' | 'flash-numbers';

