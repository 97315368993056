import { CurrencyPipe, UpperCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonText, IonBadge, IonButton, IonIcon, IonCardTitle, NavController } from '@ionic/angular/standalone';
import { BaseClass, OperationInProgressComponent, PadStartPipe, Transaction, TransactionBuyers, TransactionService, ClientService } from '@common';
import { flashNumbers$, raffle } from '../../raffle-details-wrapper/raffle-details-wrapper.component';
import { chevronBack } from 'ionicons/icons';
import { addIcons } from 'ionicons';

addIcons({ chevronBack });

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
  standalone: true,
  imports: [IonCardTitle, IonIcon, IonButton, IonBadge, IonText, IonCardContent, IonCardSubtitle, IonCardHeader, IonCard, IonContent, CurrencyPipe, OperationInProgressComponent, PadStartPipe, UpperCasePipe]
})
export class TransactionDetailsComponent extends BaseClass() implements OnInit {

  transaction?: Transaction | TransactionBuyers;

  raffle = raffle;
  flashNumbersOnly: Array<number>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navController: NavController,
    private transactionService: TransactionService,
    private clientService: ClientService,
  ) {
    super();
    this.transaction = this.router?.getCurrentNavigation()?.extras?.state?.['transaction'];

    const sub = flashNumbers$.subscribe({
      next: (flashNumbers) => {
        if (flashNumbers) this.flashNumbersOnly = flashNumbers.map(x => x.flash_number);
      }
    });
    this.appendSubscription(sub);
  }

  ngOnInit() {
    if (!this.transaction && this.route.snapshot.params?.['id'])
      this.getTransaction(this.route.snapshot.params['id']);
  }

  getTransaction(id: string) {
    this.transactionService.getTransactionById(id).then((res) => {
      if (res.transaction) this.transaction = res.transaction;
      console.log(res);
    });
  }

  // getClient() {
  //   this.clientService.
  // }

  back() {
    this.navController.back();
  }

}
