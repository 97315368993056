<ion-content>
  @if (!(flashNumbers$ | async)) {
    <operation-in-progress />
  } @else {
    @if ((flashNumbers$ | async)?.length === 0) {
      <div class="absolute flex align-center justify-center width-100 height-100">
        <ion-note class="italic" color="warning"> Nenhum número relâmpago cadastrado </ion-note>
      </div>
    } @else {
      <ion-list lines="none" class="padding-inline-sm padding-vertical-md">
        @for (flashNumber of flashNumbers$ | async; track $index) {
          <ion-item>
            <ion-label class="flex-column">
              <ion-text class="number bold">{{ flashNumber.flash_number | padStart: raffle.amount_numbers + raffle.start_at }} </ion-text>
              <ion-text [color]="flashNumber.user ? null : 'medium'" [ngClass]="{ 'small italic': !flashNumber.user, 'linear-g-color bold': flashNumber.user }">{{ flashNumber.user?.name || "Não sorteado ainda" }} </ion-text>
            </ion-label>
            @if (showLockControls) {
              @if (!flashNumber.locked) {
                <ion-button fill="solid" slot="end" (click)="unlockFlashNumber(flashNumber)">
                  <ion-text> LIBERAR </ion-text>
                </ion-button>
              } @else {
                @if (!flashNumber.user) {
                  <ion-chip color="warning" slot="end"> LIBERADO </ion-chip>
                } @else {
                  <ion-chip color="success" slot="end"> SORTEADO </ion-chip>
                }
              }
            }
          </ion-item>
        }
      </ion-list>
    }

    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button color="light">
        <ion-icon name="ellipsis-horizontal" />
      </ion-fab-button>
      <ion-fab-list side="start">
        <ion-fab-button id="add-flash-numbers-btn" color="danger">
          <ion-icon name="add" />

          <ion-modal trigger="add-flash-numbers-btn" [keyboardClose]="false" [backdropDismiss]="false" class="blurred-bg">
            <ng-template>
              <edit-flash-numbers [raffle]="raffle" [currentFlashNumbers]="flashNumbers$ | async" />
            </ng-template>
          </ion-modal>
        </ion-fab-button>

        <ion-fab-button [color]="showLockControls ? 'success' : 'dark'" (click)="showLockControls = !showLockControls">
          <ion-icon [name]="showLockControls ? 'lock-open' : 'lock-closed'" />
        </ion-fab-button>
      </ion-fab-list>
    </ion-fab>
  }
</ion-content>
