import { Component, OnInit } from '@angular/core';
import { Raffles } from '@common/models';
import { IonContent, IonFab, IonFabButton, IonIcon } from "@ionic/angular/standalone";
import { BaseClass, RafflesListComponent, Session, ToastService, session$ } from '@common/globals';
import { RaffleService } from '@common/services';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-raffles',
  templateUrl: './raffles.component.html',
  styleUrls: ['./raffles.component.scss'],
  standalone: true,
  imports: [IonIcon, IonFabButton, IonFab, IonContent, RafflesListComponent, RouterLink],
})
export class RafflesComponent extends BaseClass() implements OnInit {

  raffles: Raffles;

  constructor(
    private raffleService: RaffleService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    this.getRaffles();
  }

  getRaffles() {
    const session = session$.value as Session<'bookie'>;
    if (!session?.bookie) {
      this.toastService.present({ message: 'Não foi possível encontrar o usuário logado.', color: 'danger' });
      return;
    }

    this.raffleService.getRafflesByBookieId(session.bookie?.id).then((res) => {
      this.raffles = res.raffles;
    }).catch((err) => {
      this.toastService.present({ message: 'Ocorreu um erro ao tentar buscar as rifas, tente novamente.', color: 'danger' });
      console.error('getRaffles', err);
    });
  }

}
