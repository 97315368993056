import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseClass } from '@common/globals';
import { IonButton, IonContent } from "@ionic/angular/standalone";

@Component({
  selector: 'app-mp-connected-successfully',
  templateUrl: './mp-connected-successfully.component.html',
  styleUrls: ['./mp-connected-successfully.component.scss'],
  standalone: true,
  imports: [IonContent, IonButton, ],
})
export class MpConnectedSuccessfullyComponent extends BaseClass() {

  constructor(
    private router: Router,
  ) {
    super();
  }

  goToHome() {
    this.router.navigate(['/home'], { replaceUrl: true });
  }
}
