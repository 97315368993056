import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import {
  Bookie,
  BookieService,
  MIN_USERNAME_LENGTH,
  OperationInProgressComponent,
  SessionStorageService,
  ConnectMercadoPago,
  cpfIsValid,
  getCurrentSession,
  AuthService,
  Session,
  TFormProfileBookie,
  AlertService,
} from '@common/globals';
import {
  IonContent,
  IonList,
  IonCard,
  IonText,
  IonCardContent,
  IonListHeader,
  IonButton, IonItem, IonBadge, IonChip, IonLabel, NavController
} from "@ionic/angular/standalone";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  standalone: true,
  imports: [IonLabel, IonChip, IonBadge, IonItem, IonButton, IonListHeader, IonCardContent, IonText, IonCard, IonList, IonContent, AsyncPipe, FormsModule, OperationInProgressComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, NgxMaskDirective],
})
export class ProfileComponent extends ConnectMercadoPago {

  bookie: Bookie;

  form: FormGroup<TFormProfileBookie> = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(11)]),
    cpf: new FormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]),
    payment_url: new FormControl(null, []),
    url_connect_mp: new FormControl('', []),
    url_facebook: new FormControl('', []),
    url_instagram: new FormControl('', []),
    url_x: new FormControl('', []),
    url_tiktok: new FormControl('', []),
  });

  session = getCurrentSession<'bookie'>();

  constructor(
    private navController: NavController,
    private authService: AuthService,
    private bookieService: BookieService,
    private sessionStorageService: SessionStorageService,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit() {
    const sub = (this.session).subscribe({
      next: async (session) => {
        if (session?.bookie?.id && !this.bookie) this.getBookie(session);
      }
    });

    this.appendSubscription(sub);
  }

  async getBookie(session: Session<'bookie'>) {
    this.bookieService.getBookie(session.bookie.id).then((res) => {
      if (!res.bookie) {
        this.toastService.present({ message: 'Erro ao carregar dados.', color: 'danger' });
        return this.goBack();
      }

      this.bookie = res.bookie;
      this.authService.setSession({ ...session, bookie: this.bookie });
      this.form.setValue({
        name: this.bookie.name || null,
        email: this.bookie.email || null,
        phone: this.bookie.phone || null,
        cpf: this.bookie.cpf || null,
        payment_url: this.bookie.payment_url || null,
        url_connect_mp: this.bookie.url_connect_mp || null,
        url_facebook: this.bookie.url_facebook || null,
        url_instagram: this.bookie.url_instagram || null,
        url_tiktok: this.bookie.url_tiktok || null,
        url_x: this.bookie.url_x || null,
      });
    }).catch(error => {
      console.log(error);
      this.toastService.present({ message: 'Erro ao carregar dados.', color: 'danger' });
      this.goBack();
    });
  }

  async save() {
    if (!this.bookie?.id) return this.toastService.present({ message: 'Erro ao salvar alterações.', color: 'danger' });

    if (this.form.pristine) return this.toastService.present({ message: 'Nenhuma alteração foi feita.', color: 'warning' });
    if (this.form.invalid) return this.toastService.present({ message: 'Verifique se todos os campos estão preenchidos corretamente.', color: 'danger' });

    const { payment_url, url_connect_mp, ...formValue }: Partial<Bookie> = this.form.value;

    if (formValue.name?.length < MIN_USERNAME_LENGTH) return this.toastService.present({ message: `O nome deve ter no mínimo ${MIN_USERNAME_LENGTH} caracteres.`, color: 'danger' });
    if (!cpfIsValid(formValue.cpf)) return this.toastService.present({ message: 'CPF inválido.', color: 'danger' });

    const loading = await this.loadingService.presentLoading("Salvando alterações...");
    this.form.disable();

    try {
      const res = await this.bookieService.updateBookie(this.bookie.id, formValue);
      if (res.response === 200) {
        this.toastService.present({ message: 'Alterações salvas com sucesso.', color: 'success' });
        this.sessionStorageService.setSession<'bookie'>({ ...this.session?.value, bookie: { ...this.bookie, ...formValue } });
      } else {
        this.toastService.present({ message: 'Erro ao salvar alterações.', color: 'danger' });
      }
    } catch (error) {
      this.toastService.present({ message: 'Erro ao salvar alterações.', color: 'danger' });
      console.log(error);
    } finally {
      loading.dismiss();
      this.form.enable();
    }
  }

  async forbiddenField(key: keyof Pick<TFormProfileBookie, 'cpf' | 'email'>) {
    let fieldLabel = '';

    switch (key) {
      case 'cpf': fieldLabel = 'CPF'; break;
      case 'email': fieldLabel = 'E-mail'; break;
    }

    await this.alertService.presentAlert('Aviso', `O campo '${fieldLabel}' não pode ser alterado após a criação da rifa`);
  }

  goBack() {
    this.navController.back();
  }
}
