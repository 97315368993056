import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { BaseClass } from '@common/globals';
import { SidenavComponent } from './sidenav/sidenav.component';
import { title$ } from '@common/globals';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
  standalone: true,
  imports: [SidenavComponent, RouterModule, IonicModule, AsyncPipe],
})
export class HomeComponent extends BaseClass() {

  title = title$;

}
