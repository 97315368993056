import { Component, Input, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AlertService, BaseClass, FlashNumberRaffle, LoadingService, Raffle, RaffleService, ToastService } from '@common/models';
import { IonContent, IonList, IonListHeader, IonLabel, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, ModalController, IonFooter, IonText } from "@ionic/angular/standalone";
import { close, save, trashOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { FormsModule } from '@angular/forms';
import { UpdateFlashNumbers } from '../../raffle-details-wrapper/raffle-details-wrapper.component';

addIcons({ close, save, trashOutline });

@Component({
  selector: 'edit-flash-numbers',
  templateUrl: './edit-flash-numbers.component.html',
  styleUrls: ['./edit-flash-numbers.component.scss'],
  standalone: true,
  imports: [IonText, IonFooter, IonIcon, IonButton, IonButtons, IonToolbar, IonTitle, IonHeader, IonLabel, IonListHeader, IonList, IonContent, MatFormFieldModule, MatInputModule, FormsModule],
})
export class EditFlashNumbersComponent extends BaseClass() implements OnInit {

  UpdateFlashNumbers = UpdateFlashNumbers;

  @Input() raffle: Raffle;
  @Input() currentFlashNumbers: Array<FlashNumberRaffle>;

  flashNumbers: Array<Partial<FlashNumberRaffle>> = [{
    flash_number: null
  }];

  max: number;
  min: number;

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private raffleService: RaffleService,
  ) {
    super();
  }

  ngOnInit() {
    this.max = (this.raffle.amount_numbers + this.raffle.start_at) - 1;
    this.min = this.raffle.start_at;
  }

  addFlashNumberArr() {
    this.flashNumbers.push({ flash_number: null });
  }

  removeFlashNumber(index: number) {
    this.flashNumbers.splice(index, 1);
  }

  async save() {
    if (this.flashNumbers.filter(x => !x.flash_number)?.length > 0) return await this.alertService.presentAlert('Erro', 'Você não pode salvar números vazios');
    const numbersWithErrors = this.flashNumbers.filter(x => x.flash_number < this.min || x.flash_number > this.max);

    if (numbersWithErrors?.length > 0) {
      const header = `Os números devem ser entre ${this.min} e ${this.max}`;
      const message = `Os números ${numbersWithErrors.map(x => x.flash_number).join(', ')} estão fora do intervalo permitido`;
      return await this.alertService.presentAlert(header, message);
    }

    const flash_numbers: Array<number> = [...new Set(this.flashNumbers.map(x => x.flash_number))];

    const repeatedNumbers = this.currentFlashNumbers?.map(x => x.flash_number)?.filter(x => flash_numbers.includes(x));

    if (repeatedNumbers?.length > 0) {
      const header = 'Números repetidos';
      const message = `Os seguintes números já estão cadastrados: \n\n ${repeatedNumbers.join(', ')}`;
      return await this.alertService.presentAlert(header, message);
    }

    const loading = await this.loadingService.presentLoading('Salvando números...Aguarde');

    this.raffleService.insertFlashNumbers(this.raffle.id, flash_numbers).then((res) => {
      if (res.response === 200) {
        const flashNumbers = Object.entries(res.message)?.filter(x => !x[1]);
        if (flashNumbers?.length > 0) return this.alertService.presentAlert('Erro', `Os seguintes números já foram vendidos: \n\n ${flashNumbers.map(x => x[0]).join(', ')}`);
        UpdateFlashNumbers.emit();
        this.toastService.present({ message: 'Números salvos com sucesso', color: 'success' });
        this.dismiss();
      } else {
        this.toastService.present({ message: 'Ocorreu um erro ao salvar os números', color: 'danger' });
      }
    }).catch((err) => {
      console.log(err);
      this.toastService.present({ message: 'Erro ao salvar números, tente novamente', color: 'danger' });
    }).finally(() => {
      loading.dismiss();
    });
  }

  onInput(ev: KeyboardEvent) {
    if (ev.key === 'Enter') this.addFlashNumberArr();
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
