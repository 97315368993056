import { Component } from '@angular/core';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { APP_ID, title$ } from '@common/globals';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(private router: Router) {}

  ngOnInit() {
    APP_ID.next('bookie');

    this.router.events.subscribe({
      next: (res) => {
        if (res instanceof ActivationStart && res?.snapshot?.routeConfig?.title) {
          title$.next(res.snapshot.routeConfig.title.toString());
        }
      }
    });
  }
}
