<div id="container">
  <ion-list lines="none" class="padding-sm">
    @for (page of pages; track $index) {
      <ion-menu-toggle [autoHide]="false">
        <ion-item class="hover-pointer" [routerLink]="page.path" routerLinkActive="active" #route="routerLinkActive">
          <ion-icon slot="start" [name]="page.icon + (route.isActive ? '' : '-outline')" />
          <ion-label> {{ page.title }} </ion-label>
        </ion-item>
      </ion-menu-toggle>
    }

    <ion-menu-toggle [autoHide]="false">
      <ion-item class="hover-pointer" [routerLink]="['/home', 'raffles', 'new']">
        <ion-icon slot="start" [name]="'add'" />
        <ion-label> Nova Rifa </ion-label>
      </ion-item>
    </ion-menu-toggle>

    <ion-item class="hover-pointer" (click)="logout()" id="logout">
      <ion-icon slot="start" name="log-out" />
      <ion-label>Sair</ion-label>
    </ion-item>
  </ion-list>
</div>
