<ion-content>
  @if (bookie) {
    <ion-list>
      <ion-card class="prevent-animation" [formGroup]="form">
        <ion-list-header> Seus dados </ion-list-header>

        <ion-card-content class="flex-column gap-sm">
          <mat-form-field appearance="outline">
            <input matInput placeholder="Nome" formControlName="name" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <input matInput placeholder="E-mail" formControlName="email" readonly (click)="forbiddenField('email')" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <input matInput placeholder="CPF" formControlName="cpf" mask="000.000.000-00" readonly (click)="forbiddenField('cpf')" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <input matInput placeholder="Telefone" formControlName="phone" mask="(00)0000-0000||(00)00000-0000" />
          </mat-form-field>

          <!-- url_facebook -->
          <mat-form-field appearance="outline">
            <input matInput placeholder="https://www.facebook.com/seu_perfil" formControlName="url_facebook" />
          </mat-form-field>
          <!-- url_instagram -->
          <mat-form-field appearance="outline">
            <input matInput placeholder="https://www.instagram.com/seu_perfil" formControlName="url_instagram" />
          </mat-form-field>
          <!-- url_x -->
          <mat-form-field appearance="outline">
            <input matInput placeholder="https://www.x.com/seu_perfil" formControlName="url_x" />
          </mat-form-field>
          <!-- url_tiktok -->
          <mat-form-field appearance="outline">
            <input matInput placeholder="https://www.tiktok.com/@seu_perfil" formControlName="url_tiktok" />
          </mat-form-field>

          <ion-chip [outline]="false" id="payment_url" class="italic padding-vertical-md" [color]="form.controls?.payment_url?.value ? 'success' : 'danger'">
            <span>Você&nbsp;</span>
            @if (!form.controls?.payment_url?.value) {
              <span>não&nbsp;</span>
            }
            <span>está conectado ao Mercado Pago</span>
          </ion-chip>

          <!-- <mat-form-field id="url_connect_mp" appearance="outline">
              <input class="margin-md" [class.italic]="!form.controls.url_connect_mp?.value" matInput placeholder="URL de conexão com Mercado Pago ainda não gerada" formControlName="url_connect_mp" [readonly]="true" />

              @if (!form.controls.url_connect_mp?.value) {
                <ion-button fill="solid" slot="end" (click)="connectMercadoPago(form, bookie)">
                  <ion-text> Gerar URL </ion-text>
                </ion-button>
              } @else {
                <ion-button fill="solid" slot="end" (click)="copyURLConnectMP(bookie)">
                  <ion-text> Copiar </ion-text>
                </ion-button>
              }
            </mat-form-field> -->

          <ion-button fill="solid" (click)="save()"> Atualizar </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-list>
  } @else {
    <operation-in-progress />
  }
</ion-content>
