import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-raffle-wrapper',
  templateUrl: './raffle-wrapper.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class RaffleWrapperComponent {

}
