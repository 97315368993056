<ion-content>
  @if (transaction?.id_mercado_pago) {
    <ion-card class="prevent-animation" mode="md">
      <ion-card-header>
        <ion-card-title class="flex align-center">
          <ion-button id="back" fill="clear" (click)="back()">
            <ion-icon slot="icon-only" name="chevron-back" />
          </ion-button>

          Transação #{{ transaction.id_mercado_pago || transaction.id }}
        </ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <h6>
          <ion-text class="bold"> Cliente: </ion-text>
          <div>
            <ion-text class="bold linear-g-color"> {{ transaction.user?.name | uppercase }} </ion-text>

          </div>
        </h6>

        <h6>
          <ion-text class="bold"> Quantidade de Cotas: </ion-text>
          <ion-text class="bold linear-g-color"> {{ transaction.amount_numbers }} </ion-text>
        </h6>

        <!-- <h6>
          <ion-text class="bold"> Valor da Cota: </ion-text>
          <ion-text class="bold linear-g-color"> {{ transaction.amount_cash / transaction.amount_numbers | currency }} </ion-text>
        </h6> -->

        <h6>
          <ion-text class="bold"> Valor Pago: </ion-text>
          <ion-text class="bold linear-g-color"> {{ transaction.amount_cash | currency }} </ion-text>
        </h6>

        @if (transaction.purchased?.length > 0) {
          <h2 class="margin-top-md">
            <ion-text class="bold"> Números da sorte: </ion-text>
            <section class="margin-top-md" id="purchased-numbers">
              @for (num of transaction.purchased; track $index) {
                <li [class.winner]="flashNumbersOnly?.includes(num)">{{ num | padStart: raffle?.amount_numbers + (raffle?.start_at || 0) || 0 }}</li>
              }
            </section>
          </h2>
        }
      </ion-card-content>
    </ion-card>
  } @else {
    <operation-in-progress />
  }
</ion-content>
