import { Component, OnInit } from '@angular/core';
import { flashNumbers$, raffle } from '../raffle-details-wrapper/raffle-details-wrapper.component';
import { AlertService, BaseClass, FlashNumberRaffle, LoadingService, OperationInProgressComponent, PadStartPipe, RaffleService, ToastService } from '@common/services';
import { IonContent, IonList, IonItem, IonLabel, IonButton, IonText, IonCard, IonCardContent, IonFab, IonFabButton, IonIcon, IonModal, IonBadge, IonChip, IonCardHeader, IonNote, IonFabList } from '@ionic/angular/standalone';
import { AsyncPipe, NgClass } from '@angular/common';
import { create, add, lockClosed, lockOpen, ellipsisHorizontal } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { EditFlashNumbersComponent } from './edit-flash-numbers/edit-flash-numbers.component';

addIcons({ create, add, lockClosed, lockOpen, ellipsisHorizontal });

@Component({
  selector: 'app-section-flash-numbers',
  templateUrl: './section-flash-numbers.component.html',
  styleUrls: ['./section-flash-numbers.component.scss'],
  standalone: true,
  imports: [IonFabList, IonNote, IonCardHeader, IonChip, IonBadge, IonModal, IonIcon, IonFabButton, IonFab, IonCardContent, IonCard, IonText, IonButton, IonLabel, IonItem, IonList, IonContent, AsyncPipe, NgClass, PadStartPipe, EditFlashNumbersComponent, OperationInProgressComponent],
})
export class SectionFlashNumbersComponent extends BaseClass() implements OnInit {

  raffle = raffle;

  flashNumbers$ = flashNumbers$;

  showLockControls: boolean = true;

  constructor(
    private raffleService: RaffleService,
    private alertService: AlertService,
    private toastService: ToastService,
    private loadingService: LoadingService,
  ) {
    super();
  }

  ngOnInit() {

  }

  async unlockFlashNumber(flashNumberRaffle: FlashNumberRaffle) {
    const confirm = await this.alertService.confirm("Liberar número", `Deseja liberar o número ${flashNumberRaffle.flash_number}?`);
    if (!confirm) return;

    const loading = await this.loadingService.presentLoading("Liberando número...Aguarde");

    this.raffleService.unlockFlashNumber(this.raffle.id, flashNumberRaffle.flash_number).then((res) => {
      if (res.response === 200) {
        this.toastService.present({ message: 'Número liberado com sucesso', color: 'success' });
        flashNumbers$.next(flashNumbers$?.value.map(x => {
          if (x.id === flashNumberRaffle.id) x.locked = true;
          return x;
        }));
      }
    }).catch((err) => {
      console.log(err);
      this.toastService.present({ message: 'Erro ao liberar número', color: 'danger' });
    }).finally(() => {
      loading.dismiss();
    });
  }

  presenteEditFlashNumbersModal(modal:IonModal) {
    console.log(modal);
    modal.present();
  }

}
