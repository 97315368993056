import { Component, OnInit } from '@angular/core';
import { BaseClass, OperationInProgressComponent, PaginatorConfig, Raffle, RaffleService, TransactionBuyers } from '@common/globals';
import { IonContent, IonList, IonItem, IonLabel, IonText, IonSkeletonText, IonNote, IonProgressBar, IonBadge } from "@ionic/angular/standalone";
import { CurrencyPipe } from '@angular/common';
import { NgxMaskPipe } from 'ngx-mask';
import { RouterLink } from '@angular/router';
import { raffle } from '../raffle-details-wrapper/raffle-details-wrapper.component';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'section-buyers-raffles',
  templateUrl: './section-buyers-raffles.component.html',
  styleUrls: ['./section-buyers-raffles.component.scss'],
  standalone: true,
  imports: [IonBadge, IonProgressBar, IonNote, IonSkeletonText, IonText, IonLabel, IonItem, IonList, IonContent, CurrencyPipe, NgxMaskPipe, RouterLink, MatPaginatorModule, OperationInProgressComponent],
})
export class SectionBuyersRafflesComponent extends BaseClass() implements OnInit {

  raffle: Raffle = raffle;
  loadingMore: boolean = true;

  transactions: Array<TransactionBuyers>;

  paginatorConfig: PaginatorConfig;

  constructor(
    private raffleService: RaffleService,
  ) {
    super();
  }

  ngOnInit() {
    this.getBuyers();
  }

  getBuyers() {
    this.loadingMore = true;
    this.raffleService.getBuyersRaffle(this.raffle.id, this.paginatorConfig?.page || 1).then(response => {
      const { transactions, ...paginatorConfig } = response;
      if (paginatorConfig) this.paginatorConfig = paginatorConfig;
      this.transactions = transactions;
    }).catch(error => {
      if (error.status === 404) return this.transactions = [];
      console.log(error)
    }).finally(() => {
      this.loadingMore = false;
    });
  }

  handlePageEvent(e: PageEvent) {
    this.paginatorConfig.page = e.pageIndex + 1;
    this.getBuyers();
  }

}
