<ion-list mode="md" class="table-list">
  @if (isDesktop) {
    <div class="header row border-top-radius-md">
      <ion-text class="client"> Cliente </ion-text>
      <ion-text class="phone"> Contato </ion-text>
      <ion-text class="amount_numbers"> Números </ion-text>
      <ion-text class="amount_cash"> Total </ion-text>
    </div>
  }

  <div class="body">
    @if (!transactions) {
      @for (_i of [1, 2, 3]; track $index) {
        <div class="row ion-text-center skeleton">
          <ion-text class="client"><ion-skeleton-text animated /></ion-text>
          <ion-text class="phone"><ion-skeleton-text animated /></ion-text>
          <ion-text class="amount_numbers"><ion-skeleton-text animated /></ion-text>
          <ion-text class="amount_cash"><ion-skeleton-text animated /></ion-text>
        </div>
      }
    }

    @for (transaction of transactions; track $index) {
      <div class="row hover-pointer" [routerLink]="[transaction.id]" [state]="{ transaction }" routerLinkActive="router-link-active">
        <ion-text class="client linear-g-color bold"> {{ transaction.user?.name }} </ion-text>
        <ion-text class="phone"> {{ transaction.user?.phone ? (transaction.user?.phone | mask: "(00) 0000-0000||(00) 9 0000-0000") : "-" }} </ion-text>
        <ion-text class="amount_numbers"> {{ transaction?.amount_numbers }} quotas </ion-text>
        <ion-text class="amount_cash">
          @if (isMobile) {
            <span>Total:</span>
          }
          {{ transaction?.amount_cash | currency: "BRL" }}
        </ion-text>
      </div>

      @if (loadingMore) {
        <operation-in-progress />
      }
    }

    @if (transactions?.length > 0 && paginatorConfig?.pages > 1) {
      <div class="row paginator">
        <mat-paginator
          #paginator
          (page)="handlePageEvent($event)"
          [length]="paginatorConfig?.rowsCount"
          [pageSize]="paginatorConfig?.pageSize"
          [disabled]="loadingMore"
          [showFirstLastButtons]="false"
          [hidePageSize]="true"
          [pageIndex]="paginatorConfig.page - 1"
        />
      </div>
    }

    @if (transactions?.length === 0) {
      <div class="footer row ion-text-center padding-block-sm">
        <ion-text>
          <ion-note> Nenhuma transação encontrada para essa rifa </ion-note>
        </ion-text>
      </div>
    }
  </div>
</ion-list>
