import { Component, OnInit } from '@angular/core';
import { IonIcon, IonContent, IonLabel, IonListHeader } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { ticket, people, cash } from 'ionicons/icons';
import { BookieService, Raffles, RafflesListComponent, Session, ToastService, TotalCard, session$ } from '@common/models';
import { BaseClass } from '@common/globals';
import { RaffleService } from '@common/services';
import { CardTotalComponent, } from '@common/components';

addIcons({ ticket, people, cash });

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  standalone: true,
  imports: [IonListHeader, IonLabel, IonContent, IonIcon, RafflesListComponent, CardTotalComponent],
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseClass() implements OnInit {

  cards: Array<TotalCard> = [];

  raffles: Raffles;

  constructor(
    private toastService: ToastService,
    private raffleService: RaffleService,
    private bookieService: BookieService,
  ) {
    super();
  }

  ngOnInit() {
    this.getRaffles();
  }

  async setCards() {
    let [qtyAllRaffles, qtyActiveRaffles, grossRevenue] = await Promise.all([
      this.getCountRaffles(),
      this.getCountRaffles(true),
      this.getGrossRevenue(),
    ]);

    if (grossRevenue == null) grossRevenue = 0;
    if (qtyAllRaffles == null) qtyAllRaffles = 0;
    if (qtyActiveRaffles == null) qtyActiveRaffles = 0;

    this.cards = [
      { label: 'Minhas Rifas - Todas', icon: 'ticket', total: isNaN(qtyAllRaffles) ? '-' : qtyAllRaffles, type: isNaN(qtyAllRaffles) ? null : 'number', path: ['/home', 'raffles'] },
      { label: 'Minhas Rifas - Ativas', icon: 'ticket', total: isNaN(qtyActiveRaffles) ? '-' : qtyActiveRaffles, type: isNaN(qtyActiveRaffles) ? null : 'number', path: ['/home', 'raffles'] },
      { label: 'Total Movimentado (R$)', icon: 'cash', total: isNaN(grossRevenue) ? '-' : grossRevenue, type: isNaN(grossRevenue) ? null : 'currency', path: ['/home', 'raffles'] },
      { label: 'Média arrecadado por rifa', icon: 'cash', total: (isNaN(grossRevenue) || isNaN(qtyAllRaffles)) ? '-' : qtyAllRaffles > 0 ? (grossRevenue / qtyAllRaffles) : 0, type: isNaN(grossRevenue) || isNaN(qtyAllRaffles) ? null : 'currency', path: ['/home', 'raffles'] }
    ];
  }

  private getSession() {
    const session = session$.value as Session<'bookie'>;
    if (!session?.bookie) {
      this.toastService.present({ message: 'Não foi possível encontrar o usuário logado.', color: 'danger' });
      return null;
    }
    return session;
  }

  getRaffles() {
    const session = this.getSession();
    if (!session) return;

    this.raffleService.getRafflesByBookieId(session.bookie?.id).then((res) => {
      this.raffles = res.raffles;
      this.setCards();
    }).catch((err) => {
      console.error('getRaffles', err);
    });
  }

  async getCountRaffles(onlyActive: boolean = false): Promise<number> {
    const session = this.getSession();
    if (!session?.bookie) return null;
    const countRes = await this.raffleService.getCountRaffles(session.bookie?.id, onlyActive ? 'ACTIVE' : null);
    return countRes.count;
  }

  async getGrossRevenue(): Promise<number> {
    const session = this.getSession();
    if (!session?.bookie) return null;
    const grossRevenue = await this.bookieService.getGrossRevenue(session.bookie?.id);
    return grossRevenue.total;
  }

}
