<ion-content>
  @if (raffle) {
    <breadcrumbs [breadcrumbs]="breadcrumbs" />

    <ion-segment mode="md" [scrollable]="true">
      @for (segment of segments; track $index) {
        <ion-segment-button [routerLink]="segment.path" routerLinkActive="segment-button-checked">
          <ion-label> {{ segment.label }} </ion-label>
        </ion-segment-button>
      }
    </ion-segment>

    <div id="content">
      <router-outlet id="router-raffle-details" />
    </div>
  } @else {
    <operation-in-progress />
  }
</ion-content>
